import React from 'react';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import PlayNowButton from '../common/PlayNowButton';
import useBreakpoint from '@/hooks/useBreakpoint';
import { cn } from '@nextui-org/react';
import { useRouter } from 'next/router';

function StopScrolling() {
  const { t } = useTranslation();
  const { isLess: isLessLg } = useBreakpoint('lg');
  const { locale } = useRouter();
  const isAr = locale === 'ar-SA';
  return (
    <div className="relative w-full min-h-[160px] sm:min-h-[300px] lg:min-h-[500px] xl:min-h-[600px] flex items-center justify-center overflow-hidden">
      <div
        className="absolute inset-0 top-0 left-0 right-0 bottom-0 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage:
            'url("/images/new-homepage/backgrounds/Background Image Bottom.webp")',
        }}
      />

      <div className="relative z-10 flex items-center justify-between flex-col min-h-[130px] sm:min-h-[250px] lg:min-h-[450px] xl:min-h-[550px] lg:pb-14">
        <h2 className="text-lg md:text-3xl lg:text-6xl font-bold text-white font-display text-center">
          {!isAr && (
            <>
              {t('Stop Scrolling')}
              <br />
            </>
          )}
          <span style={{ fontSize: 'calc(100% - 25%)' }}>
            {t('Start_Playing')}
          </span>
        </h2>
        <PlayNowButton
          size={isLessLg ? 'md' : 'lg'}
          classNames={cn(isLessLg ? 'w-40 xl:w-52 text-base' : '')}
        />
      </div>
    </div>
  );
}

export default StopScrolling;
