import axiosAPI from '@/lib/axiosAPI';
import { ResData, TopWinner } from '@/types/types';
import { useQuery } from '@tanstack/react-query';

const useTopWinners = () => {
  const topWinners = useQuery<ResData<TopWinner>>({
    queryKey: ['home-top-winners'],
    queryFn: () => axiosAPI.get('extra/topEarners'),
  });

  return topWinners;
};

export default useTopWinners;
