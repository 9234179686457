import React from 'react';
import { Card, CardBody, Avatar, Image } from '@nextui-org/react';
import Flag from 'react-world-flags';
import { TopWinner } from '@/types/types';

const TopWinnerCard: React.FC<{ user: TopWinner }> = ({ user }) => {
  const { username, country_code, total, profile_pic } = user;
  return (
    <Card
      className=" dark:bg-jacarta-800 border-none overflow-visible"
      shadow="sm"
      radius="sm"
      dir="ltr"
    >
      <CardBody className="flex flex-col items-center justify-center overflow-visible gap-4 p-3 pt-12 relative">
        <Avatar
          src={profile_pic}
          className="w-20 h-20 rounded-md absolute start-1/2 -translate-x-1/2 -top-1 -translate-y-1/2 shadow-sm"
          // isBordered
          color="success"
        />

        <div className="flex flex-col items-center gap-2">
          <div className="flex-center gap-1 lg:gap-2 w-full flex-wrap">
            <h3 className="text-base font-bold">{username}</h3>

            <Flag
              code={country_code}
              style={{
                width: '32px',
              }}
            />
          </div>

          <span className="text-green-success text-3xl font-bold">
            ${Number(total).toFixed(0)}
          </span>
        </div>
      </CardBody>
    </Card>
  );
};

export default TopWinnerCard;
