import { Button } from '@nextui-org/react';
import { t } from 'i18next';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import React from 'react';
import useHasLiveTours from '../../../hooks/useHasLiveTours';

export default function PlayNowButton({ classNames = '', size = 'lg' }) {
  const { status } = useSession();
  const { hasLive } = useHasLiveTours();

  return (
    <Link
      href={
        status === 'authenticated'
          ? `/dashboard${hasLive ? '?t=live' : ''}`
          : '/login'
      }
    >
      <Button
        radius="full"
        size={size ?? 'lg'}
        className={`cursor-pointer bg-accent hover:bg-accent-dark w-60 xl:w-72 font-semibold text-white transition-all gap-3 text-lg shadow-md hover:-translate-y-1 ${classNames}`}
      >
        <span>{t('Play Now')}</span>
        <span className="animate-play-icon">🕹️</span>
      </Button>
    </Link>
  );
}
