import { t } from 'i18next';
import { homeSectionTitleClasses } from '../constants';
import TopWinnerCard from '../cards/TopWinnerCard';
import useTopWinners from '@/hooks/query-hooks/home/useTopWinners';
import { cn, Spinner } from '@nextui-org/react';
import useBreakpoint from '@/hooks/useBreakpoint';
import TopWinnerMobileCard from '../cards/TopWinnerMobileCard';
import DiscordButton from '@/components/navbar/DiscordButton';

function TopWinners() {
  const { data, isPending } = useTopWinners();
  const { isLess: isLessSm } = useBreakpoint('sm');
  return (
    <section className="py-6">
      <div className="container mx-auto">
        <h3 className={cn(homeSectionTitleClasses, 'sm:mb-14')}>
          {t('Top GamerG Winners')}
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-3 2xl:gap-5 sm:gap-y-14">
          {isPending ? (
            <Spinner />
          ) : isLessSm ? (
            data?.data
              ?.slice(0, 4)
              .map((winner) => (
                <TopWinnerMobileCard key={winner.user_id} user={winner} />
              ))
          ) : (
            data?.data?.map((winner) => (
              <TopWinnerCard key={winner.user_id} user={winner} />
            ))
          )}
        </div>
        <div className="flex-center mt-4 md:mt-6">
          <DiscordButton />
        </div>
      </div>
    </section>
  );
}

export default TopWinners;
