import { useTheme } from 'next-themes';
import { partners_logos } from '../constants';
import { Image } from '@nextui-org/react';

export default function Partners() {
  const { resolvedTheme: theme } = useTheme();

  return (
    <section className="border-t-2 border-jacarta-300 dark:border-jacarta-600">
      <div
        className="container mx-auto gap-2 flex items-center justify-around flex-col sm:flex-row py-4"
        dir="ltr"
      >
        {partners_logos[theme as 'light' | 'dark']?.map((logo, index) => (
          <div
            className="flex justify-between w-full sm:w-auto sm:flex-col items-center font-display"
            key={index}
          >
            <h3 className="text-base sm:text-lg lg:text-xl font-semibold">
              {logo.name}
            </h3>
            <Image src={logo.img} alt={logo.name} className="w-[200px]" />
          </div>
        ))}
      </div>
    </section>
  );
}
