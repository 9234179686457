import axiosAPI from '@/lib/axiosAPI';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

const useTotalWinnings = () => {
  const totalWinnings = useQuery<{ data: string }>({
    queryKey: ['home-total-winnings'],
    queryFn: () => axiosAPI.get('extra/totalWinnings'),
  });

  return totalWinnings;
};

export default useTotalWinnings;
