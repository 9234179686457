import { Image } from '@nextui-org/react';
import { t } from 'i18next';
import { homeSectionTitleClasses, paymentMethods } from '../constants';
import { useTheme } from 'next-themes';

export default function PaymentMethods() {
  const { resolvedTheme: theme } = useTheme();

  return (
    <section className="py-6">
      <div className="container mx-auto">
        <h3 className={homeSectionTitleClasses}>{t('home_payment_methods')}</h3>
        <div className="w-full flex flex-wrap items-center justify-center lg:justify-between gap-5 lg:gap-0">
          {paymentMethods[theme as 'light' | 'dark']?.map((method) => (
            <Image
              key={method.alt}
              src={method.image}
              alt={method.alt}
              className="object-cover w-[100px] md:w-[140px] lg:w-[170px]"
            />
          ))}
        </div>
      </div>
    </section>
  );
}
