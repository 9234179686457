import { t } from 'i18next';
import PlayNowButton from '../common/PlayNowButton';
import { cn, Image } from '@nextui-org/react';
import useBreakpoint from '@/hooks/useBreakpoint';
import { useRouter } from 'next/router';

export default function UnlimitedTours() {
  const { isLess: isLessLg } = useBreakpoint('lg');
  return (
    <section className="py-6 lg:py-8">
      <div className="container mx-auto gap-4 lg:gap-6 flex items-center justify-between flex-col lg:flex-row">
        {isLessLg ? (
          <ForSmallScreens isLessLg={isLessLg} />
        ) : (
          <ForLargeScreens />
        )}
      </div>
    </section>
  );
}

const ForLargeScreens = () => {
  return (
    <>
      <SectionImage />
      <div className="text-center lg:max-w-[47%] flex-center flex-col gap-10">
        <div className="flex-center flex-col gap-5 lg:gap-10">
          <Title />
          <Paragraph />
        </div>
        <PlayNowButton />
      </div>
    </>
  );
};

const ForSmallScreens = ({ isLessLg }) => {
  return (
    <>
      <Title />
      <SectionImage />
      <Paragraph />
      <PlayNowButton
        size={isLessLg ? 'md' : 'lg'}
        classNames={cn(isLessLg ? 'w-40 xl:w-52 text-base' : '')}
      />
    </>
  );
};

const SectionImage = () => {
  return (
    <div className="w-full lg:max-w-[50%]">
      <Image
        src="/images/new-homepage/large-pics/Unlimited Tournaments.webp"
        alt="home tournaments"
        className="object-cover w-full"
        classNames={{
          wrapper: '!max-w-full',
        }}
      />
    </div>
  );
};

const Title = () => {
  const { locale } = useRouter();
  const isAr = locale === 'ar-SA';
  return (
    <h3
      className={cn(
        'text-center font-display text-xl lg:text-5xl capitalize flex flex-col tracking-wider lg:tracking-normal font-bold md:text-3xl',
        isAr && 'flex-col-reverse',
      )}
    >
      <span>{t('Unlimited')}</span>
      <span>{t('Instant Competitions')}</span>
    </h3>
  );
};

const Paragraph = () => {
  return (
    <p className="text-jacarta-500 dark:text-jacarta-200 text-center text-sm lg:text-[18px]">
      {t(
        'Experience the thrill of unlimited instant competitions, where the excitement never ends and huge prizes await! Simply connect your game and let the gaming adventures begin!',
      )}
    </p>
  );
};
