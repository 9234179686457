import React from 'react';
import Link from 'next/link';
import { UserImage } from '../../dashboard/live/userAvatar';
import Flag from 'react-world-flags';
import { TopWinner } from '@/types/types';
import { Card, CardBody } from '@nextui-org/react';

function TopWinnerMobileCard({ user }: { user: TopWinner }) {
  return (
    <Card
      className="w-full border border-jacarta-200 dark:border-jacarta-600 rounded-md"
      dir="ltr"
    >
      <CardBody className="flex flex-row items-center gap-2 w-full p-1.5">
        <Link
          className="flex items-center gap-2 w-max flex-1 "
          href={`/u/${user?.username}`}
        >
          <UserImage user={user} hasBorder className="rounded-md" />

          <div className="flex flex-col items-start justify-center -space-y-1">
            <span className="font-display text-medium">{user?.username}</span>
            <Flag
              code={user?.country_code}
              style={{
                width: '24px',
                height: '24px',
              }}
            />
          </div>
        </Link>

        <span className="text-green-success font-display text-lg me-3 font-bold">
          {user.total}$
        </span>
      </CardBody>
    </Card>
  );
}

export default TopWinnerMobileCard;
